import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { CMSItem, CMSTag } from '@oma-kala-shared/core/model';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { filterCmsItemsByTag, getLocalizedLinkTag, isLinkTypeLoggedInOnly } from 'app/logic/CmsService';
import { getMenuItems } from 'app/logic/Routes';
import { selectCmsLinks } from 'app/state/cms/cmsSlice';
import { logoutUser } from 'app/state/thunks';
import { selectIsLoggedIn } from 'app/state/user/userSlice';

import { DrawerButton } from './DrawerButton';
import { DrawerFolder } from './DrawerFolder';
import { DrawerItem } from './DrawerItem';
import { DrawerFolderName } from './types';
import { DrawerLink } from './DrawerLink';
import { getCurrentLanguage } from 'app/logic/LanguageService';

interface DrawerProps {
    isOpen: boolean;
}

export const Drawer = ({ isOpen }: DrawerProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const links: CMSItem[] = useSelector(selectCmsLinks);
    const isLoggedIn: boolean = useSelector(selectIsLoggedIn);
    const { AppDrawer, DrawerHeader } = drawerStyledComponents;
    const [selectedFolder, setSelectedFolder] = React.useState<DrawerFolderName | null>(null);
    const routesToDisplay = getMenuItems();
    const lang = getCurrentLanguage();

    const isLinkHidden = (linkItem: CMSItem) => {
        return !isLoggedIn && isLinkTypeLoggedInOnly(linkItem);
    };

    const informationLinks: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_LEFT_FI,
            swedish: CMSTag.LINK_LEFT_SV,
            english: CMSTag.LINK_LEFT_EN,
            sami: CMSTag.LINK_LEFT_SE,
        })
    ).filter(link => !isLinkHidden(link));
    const externalLinks: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_MIDDLE_FI,
            swedish: CMSTag.LINK_MIDDLE_SV,
            english: CMSTag.LINK_MIDDLE_EN,
            sami: CMSTag.LINK_MIDDLE_SE,
        })
    ).filter(link => !isLinkHidden(link));
    const lukeLinks: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_RIGHT_FI,
            swedish: CMSTag.LINK_RIGHT_SV,
            english: CMSTag.LINK_RIGHT_EN,
            sami: CMSTag.LINK_RIGHT_SE,
        })
    ).filter(link => !isLinkHidden(link));
    const adminLinks: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_ADMIN_FI,
            swedish: CMSTag.LINK_ADMIN_SV,
            english: CMSTag.LINK_ADMIN_EN,
            sami: CMSTag.LINK_ADMIN_SE,
        })
    ).filter(link => !isLinkHidden(link));
    const catchlinks: CMSItem[] = filterCmsItemsByTag(
        links,
        getLocalizedLinkTag(i18n.language, {
            finnish: CMSTag.LINK_MIDDLE_FI,
            swedish: CMSTag.LINK_MIDDLE_SV,
            english: CMSTag.LINK_MIDDLE_EN,
            sami: CMSTag.LINK_MIDDLE_SE,
        })
    ).filter(link => !isLinkHidden(link));

    const menuLinks: { [key in DrawerFolderName]: CMSItem[] } = {
        [DrawerFolderName.SERVICE_INFORMATION]: informationLinks,
        [DrawerFolderName.LINKS]: externalLinks,
        [DrawerFolderName.CATCH_DECLARATION]: [],
        [DrawerFolderName.ADMIN]: adminLinks,
        [DrawerFolderName.LUKE]: lukeLinks,
        [DrawerFolderName.SUPPORT_AND_FEEDBACK]: [],
        [DrawerFolderName.PUSH_NOTIFICATIONS]: [],
    };

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate('/');
    };

    return (
        <AppDrawer variant="permanent" open={isOpen}>
            <div style={{ flex: 1 }}>
                <DrawerHeader />
                <Divider />
                <List>
                    {routesToDisplay.map((routes, key) => {
                        if (routes.subMenu) {
                            const links = menuLinks[routes.cmsLink as DrawerFolderName];
                            return (
                                <DrawerFolder key={key} title={routes.name} menuItems={routes.subMenu} allLinks={menuLinks} links={links} />
                            );
                        } else {
                            const links = menuLinks[routes.cmsLink as DrawerFolderName];
                            return (
                                <>
                                    <DrawerItem
                                        key={key}
                                        isOpen={true}
                                        path={routes.path}
                                        title={routes.name}
                                        icon={routes.icon}
                                        dangerousHtmlAllowed={routes.dangerousHtmlAllowed ?? false}
                                    />
                                    <Divider />
                                    {links?.map((l, i) => {
                                        return <DrawerLink key={i} isOpen={true} link={l} />;
                                    })}
                                </>
                            );
                        }
                    })}
                </List>
            </div>
            <div>
                {isLoggedIn && (
                    <List>
                        <Divider />
                        <DrawerButton isOpen={isOpen} title={t('common.logout')} icon={<ExitToAppIcon />} onClick={handleLogout} />
                    </List>
                )}
            </div>
        </AppDrawer>
    );
};

export const drawerStyledComponents = {
    AppDrawer: styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
        width: helpers.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...helpers.openedMixin(theme),
            '& .MuiDrawer-paper': helpers.openedMixin(theme),
        }),
        ...(!open && {
            ...helpers.closedMixin(theme),
            '& .MuiDrawer-paper': helpers.closedMixin(theme),
        }),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    })),
    DrawerHeader: styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    })),
};

const helpers = {
    openedMixin: (theme: Theme): CSSObject => ({
        width: helpers.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    }),

    closedMixin: (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    }),

    drawerWidth: 258,
};
