import {
    DeclarationCatchDetails,
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementPropertyName,
    FishingType,
} from '@oma-kala-shared/core/model';
import { useTranslation } from 'react-i18next';
import { FISH_ICONS } from 'resources/AppIcon';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { appTheme } from 'app/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import { Alert, Card, CardActionArea, CardContent, CardHeader, Grid, IconButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { formatDynamicProperty, sortCatchProperties } from 'app/utils/Utils';
import { getCurrentLanguage } from 'app/logic/LanguageService';

/**
 * Create a default update request for the given user data
 * @param {UserData | null} userData
 * @return {UpdateUserRequest}
 */

/**
 *
 * @param {data} data
 * @return {JSX.Element}
 */
type DeclarationCatchItemProps = {
    catchItem: DeclarationCatchDetails;
    onEdit?: () => void;
    onCopy?: () => void;
    onSelect?: (catchDetails: DeclarationCatchDetails) => void;
    onDelete?: () => void;
    onUndoDelete?: () => void;
    showUnSavedNotification?: boolean;
    isListView: boolean;
};

export const DeclarationCatchItem = ({
    catchItem,
    onEdit,
    onCopy,
    onDelete,
    onUndoDelete,
    onSelect,
    showUnSavedNotification,
    isListView,
}: DeclarationCatchItemProps) => {
    const { t } = useTranslation();
    const lang = getCurrentLanguage();
    const showActionButtons = onEdit !== undefined || onCopy !== undefined || onDelete !== undefined;
    const isDeleted = catchItem.deletionReason && catchItem.deletionReason.length > 0;

    const getLocationLabel = () => {
        if (catchItem.generalizedLocation === null) {
            return t('catchDeclaration.noLocationSetInfo');
        }

        // TODO: Need to recheck
        if (catchItem.generalizedLocation) {
            return catchItem.generalizedLocation.featureLabel;
        } else {
            if (!isListView) {
                return t('catchDeclaration.newLocationSetInfo');
            } else {
                return 'N/A';
            }
        }
    };

    const usesGillnetting = (properties: DeclarationCatchProperty[]) => {
        const gear = properties.find(x => x.propertyName === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR);
        if (!gear) return false;
        return gear.value === FishingType.GILLNETTING;
    };

    const hidden = (property: DeclarationCatchProperty, properties: DeclarationCatchProperty[]) => {
        switch (property.propertyName) {
            case DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING:
            case DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT:
            case DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH:
            case DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH:
                if (!usesGillnetting(properties)) {
                    return true;
                }
                break;
        }
        return false;
    };

    const onClickHandler = () => {
        if (onSelect) {
            onSelect(catchItem);
        }
    };

    return (
        <Card sx={{ minWidth: 275, m: 2 }} elevation={4}>
            {catchItem.deletionReason && (
                <CardHeader
                    title={
                        <Alert severity="error">
                            {t('catchDeclaration.declarationCatchDetailDeleteAlert').replace('{}', catchItem.deletionReason)}
                        </Alert>
                    }
                />
            )}
            {catchItem.tempId && showUnSavedNotification && (
                <CardHeader title={<Alert severity="error">{t('catchDeclaration.declarationCatchDetailUnsavedAlert')}</Alert>} />
            )}
            <CardContent sx={{ position: 'relative' }}>
                {onSelect && (
                    <CardActionArea
                        sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                        onClick={onClickHandler}
                    />
                )}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <ListItemText
                                    primary={<Typography style={{ fontWeight: 'bolder' }}>{t('catchDeclaration.species')}</Typography>}
                                    secondary={
                                        <Typography style={{ fontWeight: 'normal' }}>
                                            {t(`taxonomyType.${catchItem.idTaxonomyRequiredTaxonomy}`)}
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ListItemText
                                    primary={<Typography style={{ fontWeight: 'bolder' }}>{t('catchDeclaration.location')}</Typography>}
                                    secondary={<Typography style={{ fontWeight: 'normal' }}>{getLocationLabel()}</Typography>}
                                />
                            </Grid>
                            {sortCatchProperties([...catchItem.catchProperties]).map(property => {
                                return (
                                    <Grid
                                        item
                                        xs={4}
                                        key={property.idRequiredTaxonomyProperty}
                                        sx={{ display: hidden(property, catchItem.catchProperties) ? 'none' : '' }}
                                    >
                                        {property.propertyName === DeclarationSpeciesRequirementPropertyName.CATCH_GEAR && (
                                            <ListItemText
                                                primary={
                                                    <Typography style={{ fontWeight: 'bolder' }}>
                                                        {t(`catchDeclaration.catchProperties.${property.propertyName}`)}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography style={{ fontWeight: 'normal' }}>
                                                        {t(`common.fishingType.${property.value}`, 'N/A')}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                        {property.propertyName !== DeclarationSpeciesRequirementPropertyName.CATCH_GEAR && (
                                            <ListItemText
                                                primary={
                                                    <Typography style={{ fontWeight: 'bolder' }}>
                                                        {t(`catchDeclaration.catchProperties.${property.propertyName}`)}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography style={{ fontWeight: 'normal' }}>
                                                        {formatDynamicProperty(property, lang)}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={showActionButtons === true ? 11 : 12}>
                                <img
                                    style={{ width: '100%', height: '100px', marginTop: '20px', objectFit: 'contain' }}
                                    alt={t('catchDeclaration.fishIcon')}
                                    src={FISH_ICONS[catchItem.idTaxonomyRequiredTaxonomy]}
                                />
                            </Grid>
                            {showActionButtons === true && (
                                <Grid item xs={1} sx={{ paddingLeft: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    {onEdit !== undefined && (
                                        <IconButton sx={ActionButtonStyle.Container} onClick={onEdit}>
                                            <ModeEditOutlinedIcon sx={ActionButtonStyle.Icon} />
                                        </IconButton>
                                    )}

                                    {onCopy !== undefined && (
                                        <IconButton sx={ActionButtonStyle.Container} onClick={onCopy}>
                                            <ContentCopyOutlinedIcon sx={ActionButtonStyle.Icon} />
                                        </IconButton>
                                    )}

                                    {onDelete !== undefined && !isDeleted && (
                                        <IconButton sx={ActionButtonStyle.Container} onClick={onDelete}>
                                            <DeleteIcon sx={ActionButtonStyle.Icon} />
                                        </IconButton>
                                    )}
                                    {onUndoDelete !== undefined && isDeleted && (
                                        <IconButton sx={ActionButtonStyle.Container} onClick={onUndoDelete}>
                                            <UndoIcon sx={ActionButtonStyle.Icon} />
                                        </IconButton>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const ActionButtonStyle = {
    Container: { border: 1, borderRadius: 1, borderColor: appTheme.palette.grey[300] },
    Icon: { width: '21px', height: '21px' },
};
