import { TextField, Paper, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'app/hooks/useDebounce';
import { t } from 'i18next';

type MapSearchInputProps = {
    initialQuery: string | null;
    onSearchQueryChange: (query: string) => void;
    showLoadingIcon: boolean;
};

const MapSearchInput = ({ onSearchQueryChange, initialQuery, showLoadingIcon }: MapSearchInputProps) => {
    const [searchQuery, setSearchQuery] = useState<string | null>(initialQuery);
    const debouncedSearchQuery: string | null = useDebounce(searchQuery, 500);

    const searchCharLimit = 3;
    const canSendSearch: boolean = debouncedSearchQuery !== null && debouncedSearchQuery.length >= searchCharLimit;

    const onChangeSearch = (query: string) => setSearchQuery(query.length > 0 ? query : null);

    useEffect(() => {
        if (!canSendSearch) {
            return;
        }

        onSearchQueryChange(debouncedSearchQuery!);
    }, [debouncedSearchQuery]);

    return (
        <Paper
            sx={{
                borderRadius: '20px',
                width: '90%',
                margin: 'auto',
                marginTop: 2,
            }}
        >
            <TextField
                id="search-bar"
                className="text"
                variant="standard"
                placeholder={t('map.searchBar.placeholder')}
                size="medium"
                inputProps={{
                    border: 'none',
                }}
                sx={{
                    width: '100%',
                    padding: '6px',
                }}
                InputProps={{
                    startAdornment: <SearchIcon aria-label="search" sx={{ marginRight: '10px' }} />,
                    endAdornment: (
                        <>{showLoadingIcon ? <CircularProgress size={24} sx={{ marginRight: '10px' }} color="inherit" /> : null}</>
                    ),
                    disableUnderline: true,
                    autoComplete: 'off',
                }}
                value={searchQuery}
                onChange={e => onChangeSearch(e.target.value)}
            />
        </Paper>
    );
};

export default MapSearchInput;
