import { UNIT_MAP, UnitType } from '..';

/**
 * This is Helper class to handle the unit
 */
class UnitHelper {
    /**
     * Get default value for requirement by type.
     * @param {string} unit Type of requirement
     * @return {UnitType}
     */
    static getUnitsForUnit = (unit: string): UnitType => {
        let metric: UnitType = [];
        // @ts-expect-error Ignore the error 'Not all code path return at all'
        UNIT_MAP.forEach((units: UnitType) => {
            if (units.includes(unit)) {
                metric = units;
                return false;
            }
        });

        return metric;
    };
}
export default UnitHelper;
