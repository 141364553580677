import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentPage } from 'app/components/layout';
import { DeclarationDetails } from '@oma-kala-shared/core/model';
import { MessageSnackbar } from 'app/components/display';
import EditDeclarationForm, { EditDeclarationFormRef } from 'app/components/declaration/form/EditDeclarationForm';
import { appTheme } from 'app/theme';
import { PageType, PageTypeEnum } from '../DeclarationListPage';

/**
 * Create a default update request for the given user data
 * @param {UserData | null} userData
 * @return {UpdateUserRequest}
 */

type DeclarationListPageEditContentProps = {
    pageType: PageType;
    onEditFormSaved: (catchDeclaration: DeclarationDetails) => void;
    onBackButtonClicked: () => void;
    editingDeclaration: DeclarationDetails;
};

/**
 * @return {JSX.Element}
 */
export function DeclarationListPageEditContent({
    editingDeclaration,
    pageType,
    onEditFormSaved,
    onBackButtonClicked,
}: DeclarationListPageEditContentProps) {
    const { t } = useTranslation();
    const catchFormRef = useRef<EditDeclarationFormRef>(null);

    const handleBackButton = () => {
        const isEditFormDirty = catchFormRef.current?.isDirty();

        if (isEditFormDirty && !window.confirm(t('common.form.leavingNotification'))) {
            return;
        }

        onBackButtonClicked();
    };

    const showCatchForm = () => {
        catchFormRef.current?.openCatchForm(pageType as 'group' | 'personal');
    };

    if (!editingDeclaration) {
        return <></>;
    }

    const handleFormSubmit = (catchDeclaration: DeclarationDetails) => {
        onEditFormSaved(catchDeclaration);
    };

    return (
        <ContentPage
            width="65%"
            title={t('catchDeclaration.editDeclaration.title')}
            extraHeader={
                <Box pb={2} sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-start' }}>
                    <Button variant="outlined" color="primary" onClick={handleBackButton}>
                        <ArrowBackIcon fontSize={'small'} />
                        <Typography sx={{ fontWeight: 700 }}>{t('common.back')}</Typography>
                    </Button>

                    <Button onClick={showCatchForm} sx={actionButtonStyle} variant="contained" color="primary">
                        <AddIcon fontSize={'small'} />
                        {t('catchDeclaration.newDeclaration.createNewCatch') as string}
                    </Button>
                </Box>
            }
        >
            <EditDeclarationForm
                ref={catchFormRef}
                declarationDetails={editingDeclaration}
                onSubmit={handleFormSubmit}
                isGroupDeclaration={pageType === PageTypeEnum.GROUP}
            />
            <MessageSnackbar />
        </ContentPage>
    );
}

const actionButtonStyle = {
    backgroundColor: appTheme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: 'rgba(0,83,166,0.75)',
    },
} as const;
