import React, { useEffect, useRef, useState } from 'react';
import { BaseFieldProps, DynamicFieldRef, DYNAMIC_FIELD_TYPE, FieldValueType, GridUnitField, UnitEnum, UnitType } from '..';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import UnitHelper from '../helpers/UnitHelper';
import * as math from 'mathjs';
import { convertToDecimal, displayDecimalValue } from 'app/utils/Utils';
import AddIcon from '@mui/icons-material/Add';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';

export type UnitFieldProps = BaseFieldProps & {
    field: GridUnitField;
};

const UnitField = React.forwardRef<DynamicFieldRef, UnitFieldProps>(({ field, onChange, helperText, error }) => {
    const { name, inputSize, value, label, sx, unit, required, type } = field;

    const [currentUnit, setCurrentUnit] = useState<string | null>(unit);
    const valueRef = useRef<FieldValueType | null>(value);
    const [valueInternal, setValueInternal] = useState(value);
    const zeroDecimal = 0;
    const twoDecimal = 2;


    if (currentUnit === null) {
        return <></>;
    }

    const detectUnitType = (unit: string): UnitType | null => {
        return UnitHelper.getUnitsForUnit(unit);
    };

    const unitFormat = (e: React.ChangeEvent<HTMLInputElement> | undefined, val?: string | number) => {
        let newValue = (e?.target?.value ?? val) as string;
        if (currentUnit === UnitEnum.Grams || currentUnit === UnitEnum.Centimeters) {
            newValue = parseFloat(newValue).toFixed(zeroDecimal);
        }
        setValueInternal(newValue);
        onChange(name, newValue);
    };

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement> | undefined, val?: string | number) => {
        const oldValue = value;
        let newValue = (e?.target?.value ?? val) as string;
        if (type !== DYNAMIC_FIELD_TYPE.INTEGER) {
            newValue = convertToDecimal(newValue);
        }

        setValueInternal(newValue);

        if (currentUnit !== unit) {
            // Converts the input to the required unit
            newValue = math.evaluate(`${newValue} ${currentUnit} to ${unit}`).toNumber().toString();
        } else {
            if (type === DYNAMIC_FIELD_TYPE.INTEGER) {
                newValue = parseInt(newValue).toString();
            } else if (type === DYNAMIC_FIELD_TYPE.FLOAT) {
                newValue = parseFloat(newValue).toFixed(twoDecimal);
            }
        }


        if (oldValue === newValue) {
            return;
        }
        valueRef.current = newValue;
        onChange(name, newValue);
    };

    const onUnitChange = (newUnit: string) => {
        setCurrentUnit(newUnit);
    };

    useEffect(() => {
        onValueChange(undefined, valueInternal.toString());
        unitFormat(undefined, valueInternal as string);
    }, [currentUnit]);

    const currencies = detectUnitType(currentUnit);

    if (currencies === null || currencies.length === 0) {
        return <></>;
    }

    const getDisplayText = (value: number | string) => {
        if (!value || value === '') return value;
        if (type === DYNAMIC_FIELD_TYPE.INTEGER) {
            return value.toString();
        }

        return displayDecimalValue((value.toString() ?? '') as string);
    };

    const baseUnits = [UnitEnum.Grams, UnitEnum.Centimeters];

    const incrementValue = () => {
        let val = parseFloat(valueInternal as string);
        switch (currentUnit) {
            case UnitEnum.Meters:
                val += 0.05;
                break;
            case UnitEnum.Centimeters:
                val += 5;
                break;
            case UnitEnum.Millimeters:
                val += 50;
                break;
            case UnitEnum.Kilograms:
                val += 0.1;
                break;
            case UnitEnum.Grams:
                val += 100;
                break;
        }
        if (baseUnits.includes(currentUnit as UnitEnum)) {
            setValueInternal(val.toFixed(zeroDecimal));
            onValueChange(undefined, val.toFixed(zeroDecimal));
        } else {
            setValueInternal(val.toFixed(twoDecimal));
            onValueChange(undefined, val.toFixed(twoDecimal));
        }
    };

    const decrementValue = () => {
        let val = parseFloat(valueInternal as string);
        switch (currentUnit) {
            case UnitEnum.Meters:
                val -= 0.05;
                break;
            case UnitEnum.Centimeters:
                val -= 5;
                break;
            case UnitEnum.Millimeters:
                val -= 50;
                break;
            case UnitEnum.Kilograms:
                val -= 0.1;
                break;
            case UnitEnum.Grams:
                val -= 100;
                break;
        }
        if (baseUnits.includes(currentUnit as UnitEnum)) {
            setValueInternal(val.toFixed(zeroDecimal));
            onValueChange(undefined, val.toFixed(zeroDecimal));
        } else {
            setValueInternal(val.toFixed(twoDecimal));
            onValueChange(undefined, val.toFixed(twoDecimal));
        }
    };

    return (
        <TextField
            label={label}
            id="filled-start-adornment"
            variant="outlined"
            sx={{
                '& .MuiInputBase-root': { padding: 0 },
                width: inputSize,
                ...(sx ?? {}),
            }}
            onKeyDown={ev => {
                // Right now we always use comma as seperator so prevent to fill in dot
                if (ev.key === '.') {
                    ev.preventDefault();
                } else if (type === DYNAMIC_FIELD_TYPE.INTEGER && ev.key === ',') {
                    ev.preventDefault();
                }
            }}
            margin="normal"
            type="number"
            value={valueInternal as number}
            error={error}
            helperText={helperText}
            onChange={e => onValueChange(e as React.ChangeEvent<HTMLInputElement>)}
            onBlur={e => unitFormat(e as React.ChangeEvent<HTMLInputElement>)}
            required={required === true}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={decrementValue}>
                            <RemoveIcon />
                        </IconButton>
                        <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={incrementValue}>
                            <AddIcon />
                        </IconButton>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentUnit}
                            onChange={e => onUnitChange(e.target.value)}
                            sx={{ border: 'none' }}
                        >
                            {currencies.map((value, key) => (
                                <MenuItem key={key} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                ),
            }}
        />
    );
});

UnitField.displayName = 'Select Field';

export default UnitField;
