import { MoveToInbox } from '@mui/icons-material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GavelIcon from '@mui/icons-material/Gavel';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { createSvgIcon } from '@mui/material';
import React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { DrawerFolderName } from 'app/components/layout/drawer/types';
import { DrawerMenuItem, RouteName, RoutePath, RouteProperties } from 'app/model/Route';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import * as Pages from '../pages';
import HomeMenuIcon from 'app/components/layout/drawer/icons/HomeMenuIcon';
import { OmaKalaUserRole, UserRole } from 'app/model';
import { getUserRoles } from './UserSettingService';
import i18next from 'i18next';

const FishingHookIcon = createSvgIcon(
    <path d="M19 9c0-1.3-.84-2.4-2-2.82V2h-2v4.18C13.84 6.6 13 7.7 13 9s.84 2.4 2 2.82V15c0 2.21-1.79 4-4 4s-4-1.79-4-4v-1h3L5 9v6c0 3.31 2.69 6 6 6s6-2.69 6-6v-3.18c1.16-.42 2-1.52 2-2.82zm-3 1c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>,
    'ContentCut'
);

export const getMenuItems = (): DrawerMenuItem[] => {
    const routes = getRoutes();
    const menu: DrawerMenuItem[] = [];
    const userRoles = getUserRoles() ?? [];

    const validRole = (roles: UserRole[], allowedRoles: OmaKalaUserRole[] | null) => {
        if (allowedRoles === null) return true;
        return userRoles.find(ur => allowedRoles.includes(ur.code));
    };

    routes
        .filter(x => x.name === RouteName.HOME && validRole(userRoles, x.allowedRoles))
        .forEach(r => {
            menu.push({
                name: i18next.t(`routes.${r.name}`, { newLine: '<br/>' }),
                icon: <HomeMenuIcon />,
                path: r.path,
            });
        });

    routes
        .filter(x => x.name === RouteName.PROFILE && validRole(userRoles, x.allowedRoles))
        .forEach(r => {
            menu.push({
                name: i18next.t(`routes.${'ACCOUNT'}`),
                path: r.path,
                subMenu: [
                    {
                        name: i18next.t(`routes.${RouteName.PROFILE}`),
                        icon: <AccountBoxIcon />,
                        path: 'profile',
                    },
                    {
                        name: i18next.t(`routes.${RouteName.GEAR}`),
                        icon: <FishingHookIcon />,
                        path: '/gear',
                    },
                    {
                        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.SERVICE_INFORMATION}`),
                        subMenu: [
                            {
                                name: i18next.t(`routes.${RouteName.ABOUT}`),
                                icon: <InfoIcon />,
                                path: '/about',
                            },
                            {
                                name: i18next.t(`routes.${RouteName.TECHNICAL_REQUIREMENTS}`),
                                icon: <IndeterminateCheckBoxIcon />,
                                path: '/technical-requirements',
                            },
                            {
                                name: i18next.t(`routes.${RouteName.PRIVACY_POLICY}`),
                                icon: <IndeterminateCheckBoxIcon />,
                                path: '/privacy-policy',
                            },
                            {
                                name: i18next.t(`routes.${RouteName.TERMS_AND_CONDITION}`),
                                icon: <GavelIcon />,
                                path: '/terms-and-conditions',
                            },
                        ],
                        cmsLink: DrawerFolderName.SERVICE_INFORMATION,
                    },
                ],
            });
        });

    if (userRoles && validRole(userRoles, [OmaKalaUserRole.ORGANIZER, OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.SVK_USER])) {
        routes
            .filter(x => x.name === RouteName.CATCH_DECLARATION_PERSONAL && validRole(userRoles, x.allowedRoles))
            .forEach(r => {
                menu.push({
                    name: i18next.t(`routes.${r.name}`, { newline: '<br/>' }),
                    icon: <MoveToInbox />,
                    path: r.path,
                    dangerousHtmlAllowed: true,
                });
            });

        routes
            .filter(x => x.name === RouteName.CATCH_DECLARATION_GROUP && validRole(userRoles, x.allowedRoles))
            .forEach(r => {
                menu.push({
                    name: i18next.t(`routes.${r.name}`, { newline: '<br/>' }),
                    icon: <MoveToInbox />,
                    path: r.path,
                    dangerousHtmlAllowed: true,
                });
            });
    }

    if (userRoles && validRole(userRoles, [OmaKalaUserRole.ADMIN, OmaKalaUserRole.EDITOR])) {
        menu.push({
            name: i18next.t(`drawerMenu.folders.${DrawerFolderName.ADMIN}`),
            subMenu: [
                {
                    name: i18next.t(`routes.${RouteName.CATCH_DECLARATION}`),
                    subMenu: [
                        {
                            name: i18next.t(`routes.${RouteName.CATCH_DECLARATION_GROUP}`, {
                                newline: '<br/>',
                            }),
                            icon: <MoveToInbox />,
                            path: RoutePath.CATCH_DECLARATION_GROUP,
                            dangerousHtmlAllowed: true,
                        },
                        {
                            name: i18next.t(`routes.${RouteName.CATCH_DECLARATION_PERSONAL}`, {
                                newline: '<br/>',
                            }),
                            icon: <MoveToInbox />,
                            path: RoutePath.CATCH_DECLARATION_PERSONAL,
                            dangerousHtmlAllowed: true,
                        },
                    ],
                },
                {
                    name: i18next.t(`drawerMenu.folders.${DrawerFolderName.PUSH_NOTIFICATIONS}`),
                    subMenu: [
                        {
                            name: i18next.t(`routes.${RouteName.SEND_NOTIFICATION}`),
                            icon: <NotificationsActiveIcon />,
                            path: '/admin/notification/send',
                        },
                        {
                            name: i18next.t(`routes.${RouteName.NOTIFICATIONS}`),
                            icon: <NotificationsIcon />,
                            path: '/admin/notification/browse',
                        },
                    ],
                },
            ],
            cmsLink: DrawerFolderName.ADMIN,
        });
    }

    menu.push({
        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.SUPPORT_AND_FEEDBACK}`),
        subMenu: [
            {
                name: i18next.t(`routes.${RouteName.SUPPORT}`),
                icon: <GavelIcon />,
                path: '/support',
            },
            {
                name: i18next.t(`routes.${RouteName.FEEDBACK}`),
                icon: <FeedbackIcon />,
                path: '/feedback',
            },
        ],
    });

    menu.push({
        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.LINKS}`),
        subMenu: [],
        cmsLink: DrawerFolderName.LINKS,
    });

    menu.push({
        name: i18next.t(`drawerMenu.folders.${DrawerFolderName.LUKE}`),
        subMenu: [],
        cmsLink: DrawerFolderName.LUKE,
    });

    return menu;
};

export const getRoutes = (): RouteProperties[] => {
    return [
        {
            name: RouteName.HOME,
            path: '/home',
            component: <Pages.NewsListPage />,
            allowedRoles: null,
        },
        {
            name: RouteName.NEWS,
            path: '/news',
            component: <Pages.NewsListPage />,
            allowedRoles: null,
        },
        {
            name: RouteName.NEWS_ITEM,
            path: '/news/:newsId',
            component: <Pages.NewsItemPage />,
            allowedRoles: null,
        },
        {
            name: RouteName.CMS_ITEM,
            path: '/pages/:cmsTagName',
            component: <Pages.CmsPostingPage />,
            allowedRoles: null,
        },
        {
            name: RouteName.PROFILE,
            path: '/profile',
            component: <Pages.ProfilePage />,
            allowedRoles: [OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER, OmaKalaUserRole.SVK_USER],
        },
        {
            path: '/feedback',
            name: RouteName.FEEDBACK,
            component: <Pages.FeedbackPage />,
            allowedRoles: null,
        },
        {
            name: RouteName.TECHNICAL_REQUIREMENTS,
            path: '/technical-requirements',
            component: <Pages.TechnicalRequirementsPage />,
            allowedRoles: null,
        },
        {
            path: '/terms-and-conditions',
            name: RouteName.TERMS_AND_CONDITION,
            component: <Pages.TermsAndConditionsPage />,
            allowedRoles: null,
        },
        {
            path: '/about',
            name: RouteName.ABOUT,
            component: <Pages.AboutPage />,
            allowedRoles: null,
        },
        {
            path: '/admin/notification/browse',
            name: RouteName.NOTIFICATIONS,
            component: <Pages.BrowsePushNotification />,
            allowedRoles: [OmaKalaUserRole.ADMIN],
        },
        {
            path: '/admin/notification/send',
            name: RouteName.SEND_NOTIFICATION,
            component: <Pages.SendPushNotification />,
            allowedRoles: [OmaKalaUserRole.ADMIN],
        },
        {
            path: '/auth',
            name: RouteName.LOGIN,
            component: <Pages.LoginPage />,
            allowedRoles: null,
            hideForLoggedIn: true,
        },
        {
            path: '/resetpassword',
            name: RouteName.RESET_PASSWORD,
            component: <Pages.ResetPasswordPage />,
            allowedRoles: null,
        },
        {
            path: '/auth/registration',
            name: RouteName.REGISTRATION,
            component: <Pages.RegistrationPage />,
            allowedRoles: null,
        },
        {
            path: '/gear',
            name: RouteName.GEAR,
            component: <Pages.GearPage />,
            allowedRoles: [OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER],
        },
        {
            path: '/gear/:gearId',
            name: RouteName.GEAR_ITEM,
            component: <Pages.GearDetailsPage />,
            allowedRoles: [OmaKalaUserRole.RECREATIONAL_FISHER, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER],
        },
        {
            name: RouteName.PRIVACY_POLICY,
            path: '/privacy-policy',
            component: <Pages.PrivacyPolicyPage />,
            allowedRoles: null,
        },
        {
            path: '/support',
            name: RouteName.SUPPORT,
            component: <Pages.SupportPage />,
            allowedRoles: null,
        },
        {
            path: RoutePath.CREATE_CATCH_DECLARATION,
            name: RouteName.CREATE_CATCH_DECLARATION,
            component: <Pages.CreateDeclarationPage />,
            allowedRoles: [
                OmaKalaUserRole.EDITOR,
                OmaKalaUserRole.ADMIN,
                OmaKalaUserRole.RECREATIONAL_FISHER,
                OmaKalaUserRole.SVK_USER,
                OmaKalaUserRole.ORGANIZER,
            ],
        },
        {
            path: '/catchDeclaration',
            name: RouteName.CATCH_DECLARATION,
            component: <Pages.DeclarationListPage />,
            allowedRoles: [
                OmaKalaUserRole.EDITOR,
                OmaKalaUserRole.ADMIN,
                OmaKalaUserRole.RECREATIONAL_FISHER,
                OmaKalaUserRole.SVK_USER,
                OmaKalaUserRole.ORGANIZER,
            ],
        },
        {
            path: RoutePath.CATCH_DECLARATION_PERSONAL,
            name: RouteName.CATCH_DECLARATION_PERSONAL,
            component: <Pages.DeclarationListPage />,
            allowedRoles: [
                OmaKalaUserRole.EDITOR,
                OmaKalaUserRole.ADMIN,
                OmaKalaUserRole.RECREATIONAL_FISHER,
                OmaKalaUserRole.SVK_USER,
                OmaKalaUserRole.ORGANIZER,
            ],
            dangerousHtmlAllowed: true,
        },
        {
            path: RoutePath.CATCH_DECLARATION_GROUP,
            name: RouteName.CATCH_DECLARATION_GROUP,
            component: <Pages.DeclarationListPage />,
            allowedRoles: [OmaKalaUserRole.EDITOR, OmaKalaUserRole.ADMIN, OmaKalaUserRole.ORGANIZER],
            dangerousHtmlAllowed: true,
        },
    ];
};
