import { OmaKalaUserRole } from 'app/model';

import { DrawerFolderName } from './../components/layout/drawer/types';

export enum RouteName {
    HOME = 'HOME',
    FEEDBACK = 'FEEDBACK',
    PROFILE = 'PROFILE',
    NEWS = 'NEWS',
    NEWS_ITEM = 'NEWS_ITEM',
    CMS_ITEM = 'CMS_ITEM',
    TECHNICAL_REQUIREMENTS = 'TECHNICAL_REQUIREMENTS',
    TERMS_AND_CONDITION = 'TERMS_AND_CONDITION',
    ABOUT = 'ABOUT',
    NOTIFICATIONS = 'NOTIFICATIONS',
    SEND_NOTIFICATION = 'SEND_NOTIFICATION',
    LOGIN = 'LOGIN',
    RESET_PASSWORD = 'RESET_PASSWORD',
    REGISTRATION = 'REGISTRATION',
    GEAR = 'GEAR',
    GEAR_ITEM = 'GEAR_ITEM',
    CREATE_CATCH_DECLARATION = 'CREATE_CATCH_DECLARATION',
    CATCH_DECLARATION_GROUP = 'CATCH_DECLARATION_GROUP',
    CATCH_DECLARATION_PERSONAL = 'CATCH_DECLARATION_PERSONAL',
    CATCH_DECLARATION = 'CATCH_DECLARATION',
    ACCOUNT = 'ACCOUNT',
    SUPPORT = 'SUPPORT',
    PRIVACY_POLICY = 'RIVACY_POLICY',
}

export enum RoutePath {
    CREATE_CATCH_DECLARATION = '/catchDeclaration/create',
    CATCH_DECLARATION_GROUP = '/catchDeclaration?type=group',
    CATCH_DECLARATION_PERSONAL = '/catchDeclaration?type=personal',
}

export interface RouteProperties {
    path: string;
    paths?: string[];
    name: RouteName;
    component: JSX.Element;
    allowedRoles: OmaKalaUserRole[] | null;
    hideForLoggedIn?: boolean;
    dangerousHtmlAllowed?: boolean;
}

export interface DrawerMenuItem {
    path?: string;
    name: string;
    icon?: JSX.Element;
    subMenu?: DrawerMenuItem[];
    cmsLink?: DrawerFolderName;
    dangerousHtmlAllowed?: boolean;
}
