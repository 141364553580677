import React, { ReactElement, useImperativeHandle, useRef, useState } from 'react';
import { TextField, InputAdornment, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { GridField, DYNAMIC_FIELD_TYPE, FieldValueType, DynamicFieldRef, GridSelectField, GridUnitField } from '.';
import SelectField from './fields/SelectField';
import UnitField from './fields/UnitField';
import CheckboxField from './fields/CheckboxField';
import { getLanguageDateFormat } from '@oma-kala-shared/core/logic';
import { getCurrentLanguage } from 'app/logic/LanguageService';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { lukeOrange } from 'app/theme';

type DynamicFieldProps = {
    field: GridField;
    onChange: (fieldName: string, value: FieldValueType) => void;
};

const DynamicField = React.forwardRef<DynamicFieldRef, DynamicFieldProps>((props, ref) => {
    const { id, name, type, inputSize, value, required, label, sx, disabled, warningText } = props.field;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const valueRef = useRef<FieldValueType | null>(null);
    const [error, setError] = useState<string[] | null>(null);
    const [valueInternal, setValueInternal] = useState(value);

    let helperText: null | ReactElement = null;
    if (error && error.length > 0) {
        helperText = (
            <>
                {error.map(item => {
                    return (
                        <span key={item}>
                            {item}
                            <br />
                        </span>
                    );
                })}
            </>
        );
    }

    useImperativeHandle(ref, () => ({
        validate: () => {},
        getValue: () => {
            return valueRef.current;
        },
        setError: (errors: string[] | null) => {
            setError(errors ?? null);
        },
    }));

    const handleFieldChange = (fieldName: string, newValue: FieldValueType) => {
        switch (type) {
            case DYNAMIC_FIELD_TYPE.DATE:
                newValue = moment(newValue as string)
                    .startOf('day')
                    .utc()
                    .toISOString();
                break;
            case DYNAMIC_FIELD_TYPE.INTEGER:
                newValue = parseInt(newValue.toString()).toString();
                setValueInternal(newValue);
                break;
            case DYNAMIC_FIELD_TYPE.FLOAT:
                newValue = parseFloat(newValue.toString()).toString();
                break;
        }

        valueRef.current = newValue;
        props.onChange(fieldName, newValue);
    };

    // Type guard for GridUnitField
    const isGridUnitField = (field: GridField): field is GridUnitField => {
        if ('unit' in field === false || (field as GridUnitField).unit === null || (field as GridUnitField).unit === 'count') {
            return false;
        }

        return true;
    };

    const getDateValue = (value: string | Moment): Moment => {
        if (typeof value === 'string') {
            return moment(value);
        }

        return value;
    };

    if (isGridUnitField(props.field)) {
        return (
            <UnitField
                error={(error && error.length > 0) || false}
                helperText={helperText ?? null}
                ref={ref}
                field={props.field}
                onChange={handleFieldChange}
            />
        );
    }
    const incrementValue = () => {
        if (!inputRef?.current) return;
        let val = parseFloat((inputRef.current.value as string) ?? '0');
        val += 1;
        inputRef.current.value = val.toString();
        valueRef.current = inputRef.current.value;
        props.onChange(props.field.name, inputRef.current.value);
    };

    const decrementValue = () => {
        if (!inputRef?.current) return;
        let val = parseFloat((inputRef.current.value as string) ?? '0');
        val -= 1;
        inputRef.current.value = val.toString();
        valueRef.current = inputRef.current.value;
        props.onChange(props.field.name, inputRef.current.value);
    };

    const getFieldComponent = () => {
        switch (type) {
            case DYNAMIC_FIELD_TYPE.CHECKBOX:
                return (
                    <CheckboxField
                        error={(error && error.length > 0) || false}
                        helperText={helperText ?? null}
                        ref={ref}
                        field={props.field as GridSelectField}
                        onChange={handleFieldChange}
                    />
                );
            case DYNAMIC_FIELD_TYPE.STRING:
                return (
                    <TextField
                        inputRef={inputRef}
                        key={id}
                        name={name}
                        disabled={disabled}
                        label={label ?? name}
                        required={required === true}
                        defaultValue={value}
                        variant="outlined"
                        fullWidth={inputSize === undefined}
                        sx={{
                            width: inputSize,
                            ...(sx ?? {}),
                        }}
                        error={(error && error.length > 0) as boolean}
                        helperText={helperText}
                        inputProps={{
                            required: true,
                            'aria-required': true,
                        }}
                        margin="normal"
                        onChange={e => handleFieldChange(name, e.target.value)}
                    />
                );
            case DYNAMIC_FIELD_TYPE.INTEGER:
                return (
                    <TextField
                        inputRef={inputRef}
                        key={id}
                        name={name}
                        disabled={disabled}
                        label={label ?? name}
                        required={required === true}
                        type="number"
                        defaultValue={value}
                        variant="outlined"
                        fullWidth={inputSize === undefined}
                        margin="normal"
                        sx={{
                            width: inputSize,
                            ...(sx ?? {}),
                        }}
                        error={(error && error.length > 0) as boolean}
                        helperText={helperText}
                        inputProps={{
                            required: true,
                            'aria-required': true,
                            min: 1,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={decrementValue}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={incrementValue}>
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={e => handleFieldChange(name, e.target.value)}
                    />
                );
            case DYNAMIC_FIELD_TYPE.DATE:
                return (
                    <DatePicker
                        key={id}
                        label={label ?? name}
                        value={getDateValue(value as string | Moment)}
                        sx={{
                            // backgroundColor: backgroundColor,
                            width: inputSize,
                            marginTop: '16px',
                            ...(sx ?? {}),
                        }}
                        slotProps={{
                            textField: {
                                inputRef,
                                error: (error && error.length > 0) as boolean,
                                helperText,
                                disabled: disabled,
                                required,
                            },
                        }}
                        disabled={disabled}
                        onChange={newValue => handleFieldChange(name, newValue as Moment)}
                        format={getLanguageDateFormat(getCurrentLanguage())}
                    />
                );
            case DYNAMIC_FIELD_TYPE.SELECT:
                return (
                    <SelectField
                        error={(error && error.length > 0) || false}
                        helperText={helperText ?? null}
                        ref={ref}
                        field={props.field as GridSelectField}
                        onChange={handleFieldChange}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {getFieldComponent()}
            {warningText && warningText.length > 0 && (
                <Typography variant="caption" display="block" gutterBottom style={{ color: lukeOrange }}>
                    {warningText}
                </Typography>
            )}
        </>
    );
});

DynamicField.displayName = 'DynamicField';

export default DynamicField;
