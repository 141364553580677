import React, { useImperativeHandle, useRef, useState } from 'react';
import {
    DeclarationCatchProperty,
    DeclarationSpeciesRequirementDetails,
    DeclarationSpeciesRequirementPropertyName,
    CatchRegistryDeclarationSpeciesRequirementPropertyNameMap,
    KnownTaxonomyType,
    DeclarationRegulation,
} from '@oma-kala-shared/core/model';

import { useTranslation } from 'react-i18next';
import {
    GridValue,
    DYNAMIC_FIELD_TYPE,
    DynamicGridRef,
    FieldValueType,
    SelectFieldItem,
    GridField,
} from 'app/components/common/dynamic-grid';
import DynamicGrid from 'app/components/common/dynamic-grid/DynamicGrid';
import CatchDeclarationRequirementHelper, { DeclarationPropertyRequirements } from '../../../helpers/CatchDeclarationRequirementHelper';
import { Box, Chip } from '@mui/material';
import moment from 'moment';

type StatisticsDetailsProps = {
    catchProperties: DeclarationCatchProperty[];
    declarationTaxonomyRequirement: DeclarationSpeciesRequirementDetails[];
    taxonomies: SelectFieldItem[];
    taxonomyRequirement: DeclarationSpeciesRequirementDetails;
    onValueChange: (latestValue: GridValue) => void;
};

export type StatisticsDetailsRef = Omit<DynamicGridRef, 'setError'>;

const StatisticsDetails = React.forwardRef<StatisticsDetailsRef, StatisticsDetailsProps>(
    ({ catchProperties, declarationTaxonomyRequirement, taxonomyRequirement, onValueChange }: StatisticsDetailsProps, ref) => {
        const gridValue = useRef<GridValue | null>({});
        const dynamicGridRef = useRef<DynamicGridRef | null>(null);
        const { t } = useTranslation();
        const requirementProperties = taxonomyRequirement.requiredTaxonomyProperties;

        const countProperty: DeclarationCatchProperty | undefined = catchProperties.find(
            (property: DeclarationCatchProperty) => property.propertyName === DeclarationSpeciesRequirementPropertyName.COUNT
        );

        const [isSingleCatch, setIsSingleCatch] = useState(!countProperty || parseInt(countProperty.value) <= 1);

        const handleValueChange = (newGridValue: GridValue) => {
            if (isSingleCatch) {
                newGridValue = handleGridValueForSingleCatch(newGridValue);
            }

            gridValue.current = {
                ...(gridValue.current ?? {}),
                ...newGridValue,
            };

            validateDetails();
            onValueChange(newGridValue);
        };

        /**
         * Get name of property to show on frontend.
         *
         * Because the enum in shared repo does not contains the word 'CUT' for adipose related fields
         * but the catch-registry service return the name contains 'CUT'
         *
         * so this function is used to get correct name to use to be able to work with enum, for example
         * use with key inside translation
         *
         * Example:
         * catch-registry return ADIPOSE_FIN_CUT_COUNT
         * enum contains ADIPOSE_FIN_COUNT
         *
         * @param {string} propertyName
         *
         * @return {string}
         */
        const getPropertyNameOnFrontend = (propertyName: string) => {
            // In case catch-registry service use difference name. Ex: ADIPOSE_FIN_CUT_COUNT instead of ADIPOSE_FIN_COUNT
            if (propertyName in CatchRegistryDeclarationSpeciesRequirementPropertyNameMap) {
                propertyName = CatchRegistryDeclarationSpeciesRequirementPropertyNameMap[propertyName];
            }

            return propertyName;
        };

        /**
         * This function is used to get correct field's name to be used in grid value
         *
         * Because the enum in shared repo does not contains the word 'CUT' for adipose related fields
         * but the catch-registry service return the name contains 'CUT'
         *
         * Example:
         * catch-registry return ADIPOSE_FIN_CUT_COUNT
         * enum contains ADIPOSE_FIN_COUNT
         *
         * @param {string} name
         *
         * @return {string}
         */
        const getFieldNameInGridValue = (name: string) => {
            const foundName = Object.keys(CatchRegistryDeclarationSpeciesRequirementPropertyNameMap).find(
                key => CatchRegistryDeclarationSpeciesRequirementPropertyNameMap[key] === name
            );

            return foundName ?? name;
        };

        const handleGridValueForSingleCatch = (gridValue: GridValue) => {
            if (gridValue !== null) {
                // In single catch, when both released and adipose fin are checked, mean that release adipose fincut is als

                if (
                    +gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] ===
                    +gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT)]
                ) {
                    gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)] =
                        +gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)];
                } else {
                    gridValue[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)] = 0;
                }
            }

            return gridValue;
        };

        useImperativeHandle(ref, () => ({
            validate: () => {
                return validateDetails();
            },
            getValue: () => {
                return gridValue.current ?? null;
            },
        }));

        const validateDetails = () => {
            let isValid = true;

            if (!dynamicGridRef.current?.validate()) {
                isValid = false;
            }

            if (
                parseInt(gridValue.current![DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT] as string) >
                parseInt(gridValue.current![DeclarationSpeciesRequirementPropertyName.COUNT] as string)
            ) {
                dynamicGridRef.current?.setError({
                    [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: t(
                        'catchDeclaration.newDeclaration.messages.releaseCountGreaterThanCountMessage'
                    ),
                });

                isValid = false;
            }

            if (
                gridValue.current![getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] &&
                parseInt(
                    gridValue.current![getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] as string
                ) > parseInt(gridValue.current![DeclarationSpeciesRequirementPropertyName.COUNT] as string)
            ) {
                dynamicGridRef.current?.setError({
                    [getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)]: t(
                        'catchDeclaration.newDeclaration.messages.adiposeFinCutCountGreaterThanCountMessage'
                    ),
                });

                isValid = false;
            }

            if (
                (gridValue.current![getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] &&
                    gridValue.current![getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)] &&
                    parseInt(
                        gridValue.current![
                            getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)
                        ] as string
                    ) >
                        parseInt(
                            gridValue.current![
                                getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)
                            ] as string
                        )) ||
                parseInt(
                    gridValue.current![
                        getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)
                    ] as string
                ) > parseInt(gridValue.current![getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT)] as string)
            ) {
                dynamicGridRef.current?.setError({
                    [getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)]: t(
                        'catchDeclaration.newDeclaration.messages.invalidAdiposeFinRealeaseCountMessage'
                    ),
                });

                isValid = false;
            }

            return isValid;
        };

        /**
         * For now fisher form only render when we have fisher requirements
         */
        if (declarationTaxonomyRequirement.length === 0) {
            return <></>;
        }

        const getFieldTypeForProperty = (property: DeclarationPropertyRequirements): DYNAMIC_FIELD_TYPE => {
            // These property need to show as checkbox in single catch mode
            const specialStatisticsFields: string[] = [
                DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT,
                DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT,
            ];

            const propertyName = getPropertyNameOnFrontend(property.name);

            if (specialStatisticsFields.includes(propertyName) && isSingleCatch) {
                return DYNAMIC_FIELD_TYPE.CHECKBOX;
            }

            return property.dataType as DYNAMIC_FIELD_TYPE;
        };

        const fieldFiltering = (property: DeclarationPropertyRequirements): boolean => {
            const detailsRequirements: string[] = [
                DeclarationSpeciesRequirementPropertyName.CATCH_DATE,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_NODE_SPACING,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_HEIGHT,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_LENGTH,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR_WIRE_STRENGTH,
                DeclarationSpeciesRequirementPropertyName.CATCH_GEAR,
            ];

            return !detailsRequirements.includes(property.name);
        };

        const visibilityHandler = (property: DeclarationPropertyRequirements): boolean => {
            const propertyName = getPropertyNameOnFrontend(property.name);

            if (isSingleCatch) {
                const singleCatchHideFields: string[] = [
                    DeclarationSpeciesRequirementPropertyName.COUNT,
                    DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT,
                ];
                return singleCatchHideFields.includes(propertyName) === false;
            }

            const bulkCatchHideFields: string[] = [DeclarationSpeciesRequirementPropertyName.LENGTH];
            return bulkCatchHideFields.includes(property.name) === false;
        };

        const fieldLabelHandler = (property: DeclarationPropertyRequirements): string => {
            const propertyName = getPropertyNameOnFrontend(property.name);

            if (isSingleCatch) {
                if (propertyName === DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT) {
                    return t('catchDeclaration.singleCatchRelease');
                } else if (propertyName === DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT) {
                    return t('catchDeclaration.singleCatchAdiposeFin');
                } else if (propertyName === DeclarationSpeciesRequirementPropertyName.WEIGHT) {
                    return t('catchDeclaration.singleCatchWeight');
                }
            }

            return t(`catchDeclaration.catchProperties.${property.name}`);
        };

        const preDefinedValues: Record<string, FieldValueType> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 0,
            [DeclarationSpeciesRequirementPropertyName.COUNT]: isSingleCatch ? 1 : 2,
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 0,
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 0,
        };

        const predefinedValidationRules: Record<string, string> = {
            [DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT]: 'numeric|min:0',
            [DeclarationSpeciesRequirementPropertyName.COUNT]: isSingleCatch ? 'numeric|min:1' : 'numeric|min:2',
            [DeclarationSpeciesRequirementPropertyName.WEIGHT]: 'numeric|min:1.0',
            [DeclarationSpeciesRequirementPropertyName.LENGTH]: 'numeric|min:1.0',
        };

        // Need to create fields from requiredFields
        let [fields, fieldValues] = CatchDeclarationRequirementHelper.generateFieldsFromRequirements(
            requirementProperties,
            fieldFiltering,
            preDefinedValues,
            visibilityHandler,
            getFieldTypeForProperty,
            predefinedValidationRules,
            undefined,
            fieldLabelHandler,
            undefined
        );

        /**
         * Update the ref value
         */
        gridValue.current = {
            ...(gridValue.current ?? {}),
            ...fieldValues,
        };

        // When we have the catch properties, need to set value to field and fieldValue ref
        if (catchProperties.length > 0) {
            catchProperties.forEach(property => {
                const name = property.propertyName?.toUpperCase() as string;

                if (fields[name]) {
                    let value = property.value;
                    if (property.dataType === DYNAMIC_FIELD_TYPE.DATE) {
                        value = moment.utc(property.value).toISOString();
                    }

                    // When the first time change from single catch to bulk catch, line below keep value of bulk amount is 2 and prevent override by catch's property
                    if (name === DeclarationSpeciesRequirementPropertyName.COUNT && isSingleCatch === false && +value === 1) {
                        value = fields[name].value.toString();
                    }

                    gridValue.current = {
                        ...(gridValue.current ?? {}),
                        [name]: value,
                    };

                    fields[name].value = value;
                }
            });
        }

        /**
         * Handle fields after merge with catch properties
         *
         * @param {Record<string, GridField>} fields
         *
         * @return {Record<string, GridField>}
         */
        const fieldPostHandler = (fields: Record<string, GridField>) => {
            fields = handleInvasiveSpecies(fields);
            fields = handleAdiposeSpecies(fields);
            return fields;
        };

        /**
         * Handle invasive species like PINK_SALMON
         *
         * @param {Record<string, GridField>} fields
         *
         * @return {Record<string, GridField>}
         */
        const handleInvasiveSpecies = (fields: Record<string, GridField>) => {
            const invasiveSpecies = [KnownTaxonomyType.PINK_SALMON];

            if (
                invasiveSpecies.includes(taxonomyRequirement.idTaxonomy) &&
                +fields[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT].value > 0
            ) {
                fields[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT].warningText = t(
                    `catchDeclaration.regulation.${DeclarationRegulation.INVASIVE_SET_FREE}`
                );
            }

            return fields;
        };

        /**
         * Handle for adipose fin species like ATLACTIC SALMON
         *
         * @param {Record<string, GridField>} fields
         *
         * @return {Record<string, GridField>}
         */
        const handleAdiposeSpecies = (fields: Record<string, GridField>) => {
            if (fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] === undefined) {
                return fields;
            }

            if (isSingleCatch) {
                if (
                    +fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)].value === 1 &&
                    +fields[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT].value === 0
                ) {
                    fields[DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT].warningText = t(
                        `catchDeclaration.regulation.${DeclarationRegulation.ADIPOSE_FIN_SET_FREE}`,
                        { species: t(`taxonomyType.${taxonomyRequirement.idTaxonomy}`) }
                    );
                }
            } else {
                if (
                    fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)] &&
                    fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)] &&
                    +fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_COUNT)].value >
                        +fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)].value
                ) {
                    fields[getFieldNameInGridValue(DeclarationSpeciesRequirementPropertyName.ADIPOSE_FIN_RELEASE_COUNT)].warningText = t(
                        `catchDeclaration.regulation.${DeclarationRegulation.ADIPOSE_FIN_SET_FREE}`,
                        { species: t(`taxonomyType.${taxonomyRequirement.idTaxonomy}`) }
                    );
                }
            }

            return fields;
        };

        fields = fieldPostHandler(fields);

        const sortFunction = (a: GridField, b: GridField, fieldsOrder: string[]) => {
            const indexA = fieldsOrder.indexOf(a.name);
            const indexB = fieldsOrder.indexOf(b.name);
            // If either value is not in the sortOrder array, it should come later if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
        };

        const sortedFields = Object.values(fields).sort((a, b) =>
            sortFunction(
                a,
                b,
                !isSingleCatch
                    ? [
                          DeclarationSpeciesRequirementPropertyName.COUNT,
                          'ADIPOSE_FIN_CUT_COUNT',
                          DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT,
                          'ADIPOSE_FIN_CUT_RELEASE_COUNT',
                      ]
                    : [
                          DeclarationSpeciesRequirementPropertyName.WEIGHT,
                          DeclarationSpeciesRequirementPropertyName.LENGTH,
                          DeclarationSpeciesRequirementPropertyName.RELEASE_COUNT,
                          'ADIPOSE_FIN_CUT_COUNT',
                      ]
            )
        );

        return (
            <>
                <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                    <Chip
                        sx={{
                            marginRight: 1,
                        }}
                        label={t('catchDeclaration.singleCatchType')}
                        onClick={() => setIsSingleCatch(true)}
                        color={isSingleCatch ? 'primary' : undefined}
                    />
                    <Chip
                        label={t('catchDeclaration.bulkCatchType')}
                        color={!isSingleCatch ? 'primary' : undefined}
                        onClick={() => setIsSingleCatch(false)}
                    />
                </Box>
                <DynamicGrid ref={dynamicGridRef} gridFields={[sortedFields]} onValueChange={handleValueChange} />
            </>
        );
    }
);

StatisticsDetails.displayName = 'StatisticsDetails';

export default StatisticsDetails;
