import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Card, CardContent, Chip, Collapse, Grid, IconButton, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DeclarationDetails, DeclarationFisherRequirementPropertyName, DeclarationSource } from '@oma-kala-shared/core/model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { appTheme } from 'app/theme';
import { selectUserIsAdmin, selectUserIsEditor } from 'app/state/user/userSlice';
import { useSelector } from 'react-redux';
import { formatDynamicProperty, sortFisherProperties } from 'app/utils/Utils';
import { DeleteDeclarationModal } from 'app/components/declaration/modal/DeleteDeclarationModal';
import { getCurrentLanguage } from 'app/logic/LanguageService';
import { defaultFormatDate } from '@oma-kala-shared/core/logic';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

type DeclarationItemProps = {
    declaration: DeclarationDetails;
    onEdit: (declaration: DeclarationDetails) => void;
    onDelete: (id: string) => void;
    itemRenderer: () => React.ReactNode;
};

const DeclarationItem = (props: DeclarationItemProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const userIsAdmin: boolean = useSelector(selectUserIsAdmin);
    const userIsEditor: boolean = useSelector(selectUserIsEditor);
    const lang = getCurrentLanguage();

    const toggleDeleteDialogOpen = () => {
        setDeleteDialogOpen(!deleteDialogOpen);
    };

    const formatSource = (source: DeclarationSource) => {
        switch (source) {
            case DeclarationSource.AUTOMATIC:
                return t('catchDeclaration.automatic');
            case DeclarationSource.MANUAL:
                return t('catchDeclaration.manual');
            case DeclarationSource.WEBFORM:
                return t('catchDeclaration.webform');
            default:
                return source;
        }
    };

    const countProperty = props.declaration.fishers[0].properties.find(
        property => property.propertyName === DeclarationFisherRequirementPropertyName.COUNT
    );

    const getBadgeDisplay = (isGroupDeclaration: boolean): string => {
        return isGroupDeclaration ? t('catchDeclaration.type.group') : t('catchDeclaration.type.personal');
    };

    const isGroupDeclaration = +countProperty!.value > 1;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            <Card
                sx={{
                    minWidth: '100%',
                    borderLeftColor: `${isGroupDeclaration ? 'secondary.light' : 'primary.main'}`,
                    borderLeftWidth: '12px',
                    borderLeftStyle: 'solid',
                }}
                elevation={4}
                raised={true}
            >
                <CardContent>
                    <Box
                        sx={{
                            marginBottom: 3,
                        }}
                    >
                        <Chip
                            label={getBadgeDisplay(isGroupDeclaration)}
                            sx={{
                                backgroundColor: isGroupDeclaration ? 'secondary.light' : 'primary.main',
                                color: 'white',
                            }}
                        />
                        <Box style={{ float: 'right' }}>
                            {(userIsAdmin || userIsEditor) && (
                                <IconButton
                                    sx={{ mr: 2, border: 1, borderRadius: 1, borderColor: appTheme.palette.grey[300] }}
                                    onClick={() => toggleDeleteDialogOpen()}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            {!(userIsAdmin || userIsEditor) && (
                                <Tooltip title={t('catchDeclaration.editDeclaration.editText')}>
                                    <IconButton
                                        sx={{
                                            mr: 2,
                                            border: 1,
                                            borderRadius: 1,
                                            borderColor: appTheme.palette.grey[300],
                                            backgroundColor: 'lightgray',
                                        }}
                                        style={{ pointerEvents: 'all', color: 'darkgrey' }}
                                    >
                                        <ModeEditOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(userIsAdmin || userIsEditor) && (
                                <IconButton
                                    sx={{ mr: 2, border: 1, borderRadius: 1, borderColor: appTheme.palette.grey[300] }}
                                    onClick={() => props.onEdit(props.declaration)}
                                >
                                    <ModeEditOutlinedIcon />
                                </IconButton>
                            )}

                            <IconButton
                                sx={{ border: 1, borderRadius: 1, borderColor: appTheme.palette.grey[300] }}
                                onClick={() => setOpen(curr => !curr)}
                            >
                                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Grid width={'100%'} container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 5 }} style={{ flex: 1 }}>
                            <Grid item xs={2}>
                                <ListItemText
                                    primary={
                                        <Typography style={{ fontWeight: 'bolder' }}>{t('catchDeclaration.catchDeclarationId')}</Typography>
                                    }
                                    secondary={
                                        <Typography style={{ fontWeight: 'normal', textAlign: 'center' }}>
                                            {props.declaration.sequentialId}
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <ListItemText
                                    primary={<Typography style={{ fontWeight: 'bolder' }}>{t('common.date')}</Typography>}
                                    secondary={
                                        <Typography style={{ fontWeight: 'normal' }}>
                                            {defaultFormatDate(moment(props.declaration.createdAt).toDate(), lang)}
                                        </Typography>
                                    }
                                />
                            </Grid>
                            {sortFisherProperties([...props.declaration.fishers[0].properties]).map(property => {
                                if (
                                    property.propertyName === DeclarationFisherRequirementPropertyName.COUNT &&
                                    isGroupDeclaration === false
                                ) {
                                    return null;
                                }

                                return (
                                    <Grid item xs={2} key={property.idFisherRequiredProperty}>
                                        <ListItemText
                                            primary={
                                                <Typography style={{ fontWeight: 'bolder' }}>
                                                    {t(`catchDeclaration.${property.propertyName}`)}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography style={{ fontWeight: 'normal' }}>
                                                    {formatDynamicProperty(property, lang)}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                            <Grid item xs={2}>
                                <ListItemText
                                    primary={<Typography style={{ fontWeight: 'bolder' }}>{t('catchDeclaration.source')}</Typography>}
                                    secondary={
                                        <Typography style={{ fontWeight: 'normal' }}>{formatSource(props.declaration.source)}</Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ marginLeft: '8px', display: 'flex' }}>{/* Buttons go here */}</Box>
                    </Box>
                    <Collapse in={open}>{props.itemRenderer && props.itemRenderer()}</Collapse>
                </CardContent>
            </Card>
            {deleteDialogOpen && (
                <DeleteDeclarationModal
                    declarationId={props.declaration.id!}
                    onClose={() => toggleDeleteDialogOpen()}
                    onSuccess={props.onDelete}
                />
            )}
        </div>
    );
};
export default DeclarationItem;
