import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { appTheme } from 'app/theme';

interface DrawerItemProps {
    isOpen: boolean;
    title: string;
    icon?: JSX.Element;
    path?: string;
    onClick?: () => void;
    dangerousHtmlAllowed: boolean;
}

export const DrawerItem = ({ isOpen, title, path, icon, onClick, dangerousHtmlAllowed = false }: DrawerItemProps) => {
    const { t } = useTranslation();
    const { ListItemWrapper } = DrawerItemStyledComponents;
    const location = useLocation();
    return (
        <ListItemWrapper
            onClick={onClick ?? undefined}
            to={path ?? ''}
            style={({ isActive }) => {
                const activeStyle = {
                    borderLeft: `4px solid ${appTheme.palette.primary.main}`,
                    backgroundColor: appTheme.palette.grey[200],
                };

                // We should compare both pathname and search because isActive just compare the pathname
                if (isActive && `${location.pathname}${location.search}` === path) {
                    return activeStyle;
                }
                return {};
            }}
        >
            <ListItem key={title} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: isOpen ? 'initial' : 'center',
                        alignItems: 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isOpen ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={dangerousHtmlAllowed ? <div dangerouslySetInnerHTML={{ __html: title }}></div> : title}
                        sx={{ opacity: isOpen ? 1 : 0 }}
                    />
                </ListItemButton>
            </ListItem>
        </ListItemWrapper>
    );
};

const DrawerItemStyledComponents = {
    ListItemWrapper: styled(NavLink)({
        color: appTheme.palette.text.primary,
        textDecoration: 'none',
        display: 'block',
    }),
};
