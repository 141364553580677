import {
    CreateDeclarationFisherDetails,
    DeclarationDetails,
    DeclarationFisherDetails,
    DeclarationFisherProperty,
    DeclarationFisherRequirementPropertyDetails,
} from '@oma-kala-shared/core/model';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import PersonalDetails from './details/PersonalDetails';
import { Chip } from '@mui/material';
import { DynamicGridRef, GridValue } from '../../../common/dynamic-grid';
import GroupDetails from './details/GroupDetails';
import { t } from 'i18next';
import { getTypeFromQueryParams } from '../../modal/DeclarationSearchModal';
import { useSelector } from 'react-redux';
import { selectUserRoles } from 'app/state/user/userSlice';
import { OmaKalaUserRole } from 'app/model';

export interface DeclarationFisherDetailProps {
    declaration: DeclarationDetails;
    declarationFisherRequirementProperties: DeclarationFisherRequirementPropertyDetails[];
    onValueChange: (fisherProperties: DeclarationFisherDetails | null) => void;
    isGroupDeclaration: boolean;
    onRendered?: (fisherProperties: DeclarationFisherDetails | null) => void;
}

export type FisherFormData = {
    firstname: string;
    lastname: string;
    address: string;
    birthdate: string;
};

export type DeclarationFisherDetailRef = {
    getDeclarationFisherDetails: () => DeclarationFisherDetails | null;
    validate: () => boolean;
};

export const DeclarationFisherDetail = React.forwardRef<DeclarationFisherDetailRef, DeclarationFisherDetailProps>((props, ref) => {
    const { declarationFisherRequirementProperties, declaration, onValueChange, isGroupDeclaration, onRendered } = props;
    const [isPersonalDetail, setIsPersonalDetail] = useState<boolean>(!isGroupDeclaration);
    const personalDetailsRef = useRef<DynamicGridRef | null>(null);
    const grouplDetailsRef = useRef<DynamicGridRef | null>(null);
    const fisherDetailsRef = useRef<GridValue | null>(null);
    const [rendered, setRendered] = useState<boolean>(false);
    const roles = useSelector(selectUserRoles);

    useImperativeHandle(ref, () => ({
        validate: () => validateForm(),
        getDeclarationFisherDetails: () => generateDeclarationFisherDetails(),
    }));

    useEffect(() => {
        if (!rendered) {
            setRendered(true);
            if (onRendered) {
                onRendered(generateDeclarationFisherDetails());
            }
        }
    }, []);

    const getFormRef = (): DynamicGridRef => {
        return isPersonalDetail ? personalDetailsRef.current! : grouplDetailsRef.current!;
    };

    const validateForm = (): boolean => {
        const ref = getFormRef();
        return ref.validate();
    };

    const handleGridValueChange = (latestValue: GridValue) => {
        fisherDetailsRef.current = latestValue;
        onValueChange(generateDeclarationFisherDetails());
    };

    const generateFisherProperties = (gridValue: GridValue): DeclarationFisherProperty[] => {
        return declarationFisherRequirementProperties.map((requiredProperty): DeclarationFisherProperty => {
            const existProperty = declaration.fishers[0]?.properties.find(property => property.propertyName === requiredProperty.name);

            return {
                id: existProperty ? existProperty.id : '',
                value: (gridValue![requiredProperty.name] ?? '').toString(),
                idFisherRequiredProperty: requiredProperty.id,
                propertyName: requiredProperty.name,
                dataType: requiredProperty.dataType,
                unit: requiredProperty.unit as string,
            };
        });
    };

    const generateDeclarationFisherDetails = (): CreateDeclarationFisherDetails | null => {
        let fisherInfo: CreateDeclarationFisherDetails = {
            properties: [],
            externalReferences: [],
        };

        if (declaration.fishers[0]) {
            fisherInfo = { ...declaration.fishers[0] };
        }

        let gridValue: GridValue = fisherDetailsRef.current;
        if (gridValue === null) {
            const ref = getFormRef();
            gridValue = ref.getValue();
        }

        fisherInfo.properties = generateFisherProperties(gridValue);

        return fisherInfo;
    };

    /**
     * For now fisher form only render when we have fisher requirements
     */
    if (declarationFisherRequirementProperties.length === 0) {
        return <></>;
    }

    const getDetailElement = () => {
        if (!isPersonalDetail) {
            return (
                <GroupDetails
                    ref={grouplDetailsRef}
                    key="fisher-group-detail"
                    requirementProperties={declarationFisherRequirementProperties}
                    fisherProperties={declaration.fishers[0]?.properties ?? []}
                    onValueChange={handleGridValueChange}
                />
            );
        }

        return (
            <PersonalDetails
                ref={personalDetailsRef}
                key="fisher-personal-detail"
                requirementProperties={declarationFisherRequirementProperties}
                fisherProperties={declaration.fishers[0]?.properties ?? []}
                onValueChange={handleGridValueChange}
            />
        );
    };
    useEffect(() => {
        const type = getTypeFromQueryParams();
        if (type === 'group') {
            setIsPersonalDetail(false);
            return;
        }
    });

    return (
        <>
            <Chip
                sx={{
                    marginRight: 1,
                    backgroundColor: isPersonalDetail ? 'primary.main' : undefined,
                    color: isPersonalDetail ? 'white' : undefined,
                }}
                label={t('catchDeclaration.fisherPersonalType') as string}
                onClick={() => setIsPersonalDetail(true)}
            />
            {roles &&
                roles.some(
                    x => x.code === OmaKalaUserRole.ADMIN || x.code === OmaKalaUserRole.EDITOR || x.code === OmaKalaUserRole.ORGANIZER
                ) && (
                    <Chip
                        sx={{
                            marginRight: 1,
                            backgroundColor: !isPersonalDetail ? 'secondary.light' : undefined,
                            color: !isPersonalDetail ? 'white' : undefined,
                        }}
                        label={t('catchDeclaration.fisherGroupType') as string}
                        onClick={() => setIsPersonalDetail(false)}
                    />
                )}
            {getDetailElement()}
        </>
    );
});

DeclarationFisherDetail.displayName = 'CreateDeclarationFisherForm';
